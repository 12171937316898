import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Price = () => {

  return (
    <Layout>
      <Seo title="Прайс-лист. Стоимость обработки" />
      <div className="py-10 font-raleway px-4 md:px-0">
        <h1 className="text-2xl font-semibold">
          Стоимость обработки квартир, участков, бытовок, деревянных конструкций
        </h1>
        <div className="text-stone-700">
          <h2 className="py-4 font-semibold">
            Истребление насекомых, грызунов, гербицидная обработка участков
          </h2>{" "}
          У нас вы можете заказать обработку садовых деревьев от болезней и
          вредителей. А также чистку бассейна, пруда, интерьерного водопада от
          водорослей и ряски, известкового налета и ржавчины. Если в вашем пруду
          обитают рыбки и посажены декоративные растения, проведем мероприятия
          для безопасного расщепления органического ила
        </div>
        <div className="max-w-5xl space-y-4">
          <h2 class="text-2xl font-semibold pb-4">
            Дезинсекция (уничтожение насекомых), цены
          </h2>
          <div className="text-gray-500 italic">Прайс-лист для юридических лиц</div>
          <div className="flex justify-between items-center p-4 bg-slate-50 hover:bg-slate-100">
            <div>
              <div className="text-lg font-semibold">Дезинсекция</div> Услуга по
              уничтожению тараканов, комаров, муравьев и других насекомых
              вредителей.
            </div>
            <div className=" font-semibold text-xl">
              от 1,5 до 5 руб. за кв. м.
            </div>
          </div>
          <div className="flex justify-between items-center p-4 bg-slate-50 hover:bg-slate-100">
            <div>
              <div className="text-lg font-semibold">Дератизация</div> Работы по
              уничтожению крыс, мышей и других видов грызунов
            </div>
            <div className=" font-semibold text-xl">
              от 0,5 до 3 руб. за кв. м.
            </div>
          </div>
          <div className="flex justify-between items-center p-4 bg-slate-50 hover:bg-slate-100">
            <div>
              <div className="text-lg font-semibold">Дезинфекция</div> Работы по
              (бактерий, вирусов, грибка, плесени), цены
            </div>
            <div className=" font-semibold text-xl">
              от 12 до 18 руб. за кв. м.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Price
